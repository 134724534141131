import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import IndexPageTemplate from '../components/layout/mdx/IndexPageLayout';
export const _frontmatter = {
  "section": {
    "about": {
      "title": "Arnaud <span class=\"text-primary\">Deprez</span>",
      "subTitle": "Creative Technical Architect",
      "services": [{
        "title": "Architecture",
        "icon": "project-diagram",
        "description": "Designs microservices in a <a href=\"https://www.reactivemanifesto.org/\">resilient, responsive and elastic architecture</a> by  applying the right tactics from <abbr title=\"Domain Driven Design\">DDD</abbr> analysis (Event Sourcing,  <abbr title=\"Command and Query Responsibility Segregation\">CQRS</abbr>, Request/Reply). Uses drawing convention such as BPMN, UML or Archimate when it helps.\n"
      }, {
        "title": "DevOps",
        "icon": "rocket",
        "description": "Designs pipeline following <a href=\"https://www.weave.works/technologies/gitops/\">GitOps</a> best practices to match your requirements like security, audit, segregation\n"
      }, {
        "title": "Code",
        "icon": "code",
        "description": "Implements from proof of concept to production following the <a href=\"https://12factor.net/\">12 factor app manifest</a>.  Use the right pattern (<abbr title=\"Object Oriented Programming\">OOP</abbr>, <abbr title=\"Functional Programming\">FP</abbr>,  <abbr title=\"Aspect Oriented Programming\">AOP</abbr>) at the right place for clean code.\n"
      }, {
        "title": "Training",
        "icon": "user-graduate",
        "description": "Helps people to get onboard with courses, presentations, socialization and mentorship from Developers to Management\n"
      }],
      "pitch": "I'm used to work in an <strong>agile iterative way</strong> with high focus on delivering usable increments to speed up adoption  and get quicker feedback for continuous improvements.  What I like the most in my job is to <strong>learn new technologies</strong> to fill my toolbox so that I can pick the right tool to solve your needs.  When I can, I also try to <strong>contribute</strong> back to open source technologies I use.\n"
    },
    "technicalSkills": {
      "programming": [{
        "title": "Backend",
        "values": ["Scala", "Akka", "Kotlin", "Nodejs", "Python", "Java", "Spring", "Camel", "Hazelcast", "Camunda", "Hibernate", "Groovy", "Sbt", "Gradle", "Maven", "Go", "C#", "Clojure", "Bash"]
      }, {
        "title": "Frontend",
        "values": ["Html 5", "Javascript", "Typescript", "Reactjs", "CSS 3", "Sass"]
      }, {
        "title": "Database",
        "values": ["PostgreSQL", "MySQL", "MongoDB", "Elasticsearch", "Cassandra", "ArangoDB"]
      }, {
        "title": "Message Bus",
        "values": ["Kafka", "RabbitMQ", "ActiveMQ"]
      }],
      "devOps": [{
        "title": "Platform",
        "values": ["Kubernetes", "Openshift", "Docker"]
      }, {
        "title": "Monitoring",
        "values": ["Prometheus", "Grafana", "Elasticsearch", "Logstash", "Fluentd", "Kibana"]
      }, {
        "title": "Security",
        "values": ["Clair", "OWASP", "Anchore", "Snyk"]
      }, {
        "title": "Tooling",
        "values": ["Git", "Jenkins", "Gitlab", "Sonarqube", "Selenium"]
      }],
      "love": [{
        "title": "Frontend",
        "values": ["Reactjs", "Nodejs"]
      }, {
        "title": "Backend",
        "values": ["Scala"]
      }, {
        "title": "Platform",
        "values": ["Kubernetes"]
      }]
    }
  }
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = IndexPageTemplate;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <style {...{
      "className": "grvsc-styles"
    }}>{`
  .grvsc-container {
    overflow: auto;
    position: relative;
    -webkit-overflow-scrolling: touch;
    padding-top: 1rem;
    padding-top: var(--grvsc-padding-top, var(--grvsc-padding-v, 1rem));
    padding-bottom: 1rem;
    padding-bottom: var(--grvsc-padding-bottom, var(--grvsc-padding-v, 1rem));
    border-radius: 8px;
    border-radius: var(--grvsc-border-radius, 8px);
    font-feature-settings: normal;
    line-height: 1.4;
  }
  
  .grvsc-code {
    display: table;
  }
  
  .grvsc-line {
    display: table-row;
    box-sizing: border-box;
    width: 100%;
    position: relative;
  }
  
  .grvsc-line > * {
    position: relative;
  }
  
  .grvsc-gutter-pad {
    display: table-cell;
    padding-left: 0.75rem;
    padding-left: calc(var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem)) / 2);
  }
  
  .grvsc-gutter {
    display: table-cell;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .grvsc-gutter::before {
    content: attr(data-content);
  }
  
  .grvsc-source {
    display: table-cell;
    padding-left: 1.5rem;
    padding-left: var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem));
    padding-right: 1.5rem;
    padding-right: var(--grvsc-padding-right, var(--grvsc-padding-h, 1.5rem));
  }
  
  .grvsc-source:empty::after {
    content: ' ';
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .grvsc-gutter + .grvsc-source {
    padding-left: 0.75rem;
    padding-left: calc(var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem)) / 2);
  }
  
  /* Line transformer styles */
  
  .grvsc-has-line-highlighting > .grvsc-code > .grvsc-line::before {
    content: ' ';
    position: absolute;
    width: 100%;
  }
  
  .grvsc-line-diff-add::before {
    background-color: var(--grvsc-line-diff-add-background-color, rgba(0, 255, 60, 0.2));
  }
  
  .grvsc-line-diff-del::before {
    background-color: var(--grvsc-line-diff-del-background-color, rgba(255, 0, 20, 0.2));
  }
  
  .grvsc-line-number {
    padding: 0 2px;
    text-align: right;
    opacity: 0.7;
  }
  
`}</style>



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      